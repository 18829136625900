// Import dependencies
import React from "react";

// Import components
import { Collapse, Button, Spin } from "antd";
import { ParagraphText } from "../../styled-components/UILibrary";
import InputField from "../form/input/inputField";

// Import styles
import "./accordion.css";
import { Constants } from "../../constants/Constants";

// Import icons
import Down from "../../assets/icons/down.svg";
import Up from "../../assets/icons/up.svg";

// Import firebase
import { getFirebase } from "../../api/firebase";

// Redux
import { connect } from "react-redux";
import { applyPromoCode } from "../../redux/actions/cartAction";
import { applyGeneralPromoCode } from "../../redux/actions/generalCartAction";

// Define custom accordion panel
const { Panel } = Collapse;
const customPanelStyle = {
  background: "#fff",
  borderRadius: 13,
  border: 0,
  overflow: "hidden",
};

/*
    Promo code accordion component
*/
class PromoCodeAccordion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      promoCodeValue: null,
      message: null,
      loading: false,
    };
    this.updatePromoCodeValue = this.updatePromoCodeValue.bind(this);
  }

  updatePromoCodeValue = value => {
    this.setState({ promoCodeValue: value });
  };

  redeemPromoCode = () => {
    this.setState({ loading: true, message: null });

    const hasPromoCode = this.props.cart.some(item => item.promo_code_value);
    if (hasPromoCode) {
      this.setState({
        message: "Promo code already applied.",
        loading: false,
      });
      return;
    }

    if (this.state.promoCodeValue && this.state.promoCodeValue !== "") {
      // Fetch data
      const app = import("firebase/app");
      const firestore = import("firebase/firestore");

      Promise.all([app, firestore]).then(([firebase]) => {
        getFirebase(firebase)
          .firestore()
          .collection("promo_codes")
          .where("name", "==", this.state.promoCodeValue)
          .get()
          .then(querySnapshot => {
            const promoCode = querySnapshot.docs[0].data();
            if (promoCode.times_redeemed < promoCode.max_redemption) {
              if (this.props.isPremiumOrder) {
                this.props.dispatch(applyPromoCode(promoCode.value));
              } else {
                this.props.dispatch(applyGeneralPromoCode(promoCode.value));
              }

              Promise.all([app, firestore]).then(([firebase]) => {
                getFirebase(firebase)
                  .firestore()
                  .collection("promo_codes")
                  .doc(querySnapshot.docs[0].id)
                  .update({
                    times_redeemed: promoCode.times_redeemed + 1,
                  })
                  .then(/* SUCCESS */)
                  .catch(/* HANDLE ERROR */);
              });

              this.setState({
                message: "Applied successfully.",
                loading: false,
              });
            } else {
              this.setState({
                message: "Promo code is not valid.",
                loading: false,
              });
            }
          })
          .catch(() => {
            this.setState({
              message: "Promo code not does not exist.",
              loading: false,
            });
          });
      });
    } else {
      this.setState({
        message: "Please enter a valid promo code.",
        loading: false,
      });
    }
  };

  render() {
    return (
      <Collapse className={"accordion-earnmore code-promo-accordion"}
        bordered={false}
        accordion
        expandIconPosition="right"
        expandIcon={({ isActive }) =>
          isActive ? (
            <Up style={{ width: "35px !important", marginRight: -5 }} alt="minus icon" />
          ) : (
            <Down style={{ width: "35px !important", height:"35px !important" }} alt="plus icon" />
          )
        }
        style={
          this.props.styles
            ? this.props.styles
            : { width: "100%", marginBottom: 25 }
        }
      >
        <Panel header={this.props.title} key="1" style={customPanelStyle}>
          <div>
            <InputField
              placeholder="Promo code"
              noForm
              allowClear
              styles={{"width":"265px"}}
              onChange={e => this.updatePromoCodeValue(e.target.value)}
            />

            {this.state.loading && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: 15,
                }}
              >
                <Spin size="large" />
              </div>
            )}
            {this.state.message && (
              <ParagraphText
                colour={
                  this.state.message === "Applied successfully."
                    ? Constants.mainGreen
                    : Constants.mainRed
                }
                margin="margin-bottom: 15px"
                center
              >
                {this.state.message}
              </ParagraphText>
            )}

            <Button
              style={{ display: "block", margin: "auto", background: "transparent" }}
              type="default"
              block
              onClick={this.redeemPromoCode}
            >
              REDEEM
            </Button>
            <div style={{ marginBottom: 10 }} />
          </div>
        </Panel>
      </Collapse>

    );
  }
}

const mapStateToProps = state => ({
  cart: state.cart,
});

export default connect(mapStateToProps)(PromoCodeAccordion);
