// Import dependencies
import React from "react";

// Import components
import Accordion from "../accordion/accordion";

// Import styles
import "./drop-downs.css";
import {Collapse} from "antd";
const { Panel } = Collapse;

class DropDowns extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customPanelStyle: {
        border: 0,
        overflow: "hidden",
      },
      data: this.props.data,
    }
  }

  componentDidMount() {
    let dataForAccordion = this.state.data;
    dataForAccordion.forEach(element => {element.active = true;});
    this.setState({data: dataForAccordion});
  }

  render() {
    return <div className="drop-downs__container" style={this.props.style}>
    {this.state.data.map((item, i) => (
        <Collapse className={"accordion-termCond faqs-accordion"}
                  bordered={false}
                  accordion
                  expandIconPosition="right"
                  expandIcon={() =>
                      {
                        if (item?.active) {
                          return <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M22.0002 14.1663H13.6668V22.4997C13.6668 23.4163 12.9168 24.1663 12.0002 24.1663C11.0835 24.1663 10.3335 23.4163 10.3335 22.4997V14.1663H2.00016C1.0835 14.1663 0.333496 13.4163 0.333496 12.4997C0.333496 11.583 1.0835 10.833 2.00016 10.833H10.3335V2.49967C10.3335 1.58301 11.0835 0.833008 12.0002 0.833008C12.9168 0.833008 13.6668 1.58301 13.6668 2.49967V10.833H22.0002C22.9168 10.833 23.6668 11.583 23.6668 12.4997C23.6668 13.4163 22.9168 14.1663 22.0002 14.1663Z" fill="#BDBDBD"/>
                          </svg>;
                        } else {
                          return <svg width="24" height="5" viewBox="0 0 24 5" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M22.0002 4.16634H2.00016C1.0835 4.16634 0.333496 3.41634 0.333496 2.49967C0.333496 1.58301 1.0835 0.833008 2.00016 0.833008H22.0002C22.9168 0.833008 23.6668 1.58301 23.6668 2.49967C23.6668 3.41634 22.9168 4.16634 22.0002 4.16634Z" fill="#BDBDBD"/>
                          </svg>;
                        }
                      }
                  }
                  onChange={() => {
                    let dataChanged = this.state.data;
                    dataChanged[i].active = !dataChanged[i].active;
                    this.setState({data: dataChanged});
                    console.log(this.state.data);
                  }}
        >
        <Panel header={item.title} key="1" style={this.state.customPanelStyle}>
          <div className={"faqs-terms-condition"}>
            {item.content}
          </div>
        </Panel>
      </Collapse>
    ))}
  </div>;
  }
}

export default DropDowns;
