// Import dependencies
import React, {useEffect, useState} from "react";
import {Link, navigate} from "gatsby";
import {Row, Col, Button, Spin, Collapse} from "antd";

// Import components
import {
    TitleText,
    ParagraphText,
} from "../../../styled-components/UILibrary";
import GeneralButton from "../buttons/GeneralButton";
import OpenWindow from "../../../assets/icons/open-window.svg";

// Import styles
import "./quote.css";
import "../../../pages/recycle.css";
import {
  Member,
  NonMember,
} from "../../../data/inputFields";
// Import redux
import {connect, useSelector} from "react-redux";
import {addItemToCart} from "../../../redux/actions/cartAction";
import {Separator} from "../../separators/separator";
import {getFirebase} from "../../../api/firebase";
import {getCartQuote} from "../../../util/cart";
import Plus from "../../../assets/icons/plus-black.svg";
import {Constants} from "../../../constants/Constants";
import ReininCoin from "../../../assets/images/reinin_coin_quote.png";

// Define custom accordion panel
const { Panel } = Collapse;
const customPanelStyle = {
    background: Constants.lightGray,
    borderRadius: 13,
    border: 0,
    overflow: "hidden",
};
/* Quote display pages */
const Quote = ({dispatch, state, selectClick}) => {



    const [loadMember, setLoadMember] = useState(false);
    const [isMember, setMember] = useState(false);
    const userToken = useSelector(state =>
        state.user.userToken ? state.user.userToken.uid : null
    );

    const cart = useSelector(state => state.cart);
    const generalCart = useSelector(state => state.generalCart);
    const products = useSelector(state => state.product);

    const quoteCart = getCartQuote(cart, generalCart);
    
    const totalCount = cart.length + generalCart.length;
    const blockCheckout = totalCount < 1;
//    const blockCheckout = quoteCart < parseInt(products.configs["minimum_checkout"]);

    useEffect(() => {

      if(state.addCartLogItem) {
        dispatch(
            addItemToCart({
                ...state.itemLog,
                weight: state.productInfo["AvgWeight"],
                category: state.productInfo["Department"]
            })
        );
        selectClick();
      }

        if (!userToken) return;
        setLoadMember(true);
        const app = import("firebase/app");
        const firestore = import("firebase/firestore");

        // load member data
        Promise.all([app, firestore])
            .then(([firebase]) =>
                getFirebase(firebase)
                    .firestore()
                    .collection("users")
                    .doc(userToken)
                    .onSnapshot(doc => {
                        // Get data and store in state
                        setMember(doc.data().membership_info.is_member);
                        setLoadMember(false);
                    })
            )
            .catch(() => setLoadMember(false));
    }, []);

    const {nonMemberQuote = 0, ...item} = state || {};
    const hasItem = Boolean(item);
    // quote is member priсe, nonMemberQuote in non member price
    const {
        condition = "",
        productName = "",
        powersOn = "",
        quote = 0,
    } = item;
    const description = hasItem
        ? `${productName} - ${condition} - ${powersOn}`
        : "";



    return (
                    <div className={loadMember ? "quote_bar hide" : "quote_bar"}>
                        <div className="quote_btn_container">
                          <div className="quote_btn_green_standard" onClick={() =>
                              {
                                dispatch(
                                  addItemToCart({
                                      ...item,
                                      quote: nonMemberQuote,
                                      weight: state.productInfo["AvgWeight"],
                                      type: "member",
                                      category: state.productInfo["Department"],
                                      otherPrices: state.productInfo[NonMember]
                                  })
                              );
                              selectClick();
                            }
                          }>
                            <span className="quote_value">{`$${hasItem ? Math.round(nonMemberQuote * 100) / 100 : 0}`}</span>
                            <span className="quote_btn_content">Standard Offer</span>
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 8.99973H12.17L7.29 13.8797C6.9 14.2697 6.9 14.9097 7.29 15.2997C7.68 15.6897 8.31 15.6897 8.7 15.2997L15.29 8.70973C15.68 8.31973 15.68 7.68973 15.29 7.29973L8.71 0.699727C8.32 0.309727 7.69 0.309727 7.3 0.699727C6.91 1.08973 6.91 1.71973 7.3 2.10973L12.17 6.99973H1C0.45 6.99973 0 7.44973 0 7.99973C0 8.54973 0.45 8.99973 1 8.99973Z" fill="white"/>
                            </svg>
                          </div>
                          <div className="quote_btn_black_member" onClick={() => {
                            if (isMember) {
                              dispatch(
                                  addItemToCart({
                                      ...item,
                                      weight: state.productInfo["AvgWeight"],
                                      type: "member",
                                      category: state.productInfo["Department"],
                                      otherPrices: state.productInfo[Member]
                                  })
                              );
                              selectClick();
                            } else {
                              navigate("/pricing");
                            }
                          }
                              }>
                              <span className="quote_value">{`$${hasItem ? Math.round(quote * 100) / 100 : 0}`}</span>
                              <span className="quote_btn_content">Offer for Members</span>
                              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 8.99973H12.17L7.29 13.8797C6.9 14.2697 6.9 14.9097 7.29 15.2997C7.68 15.6897 8.31 15.6897 8.7 15.2997L15.29 8.70973C15.68 8.31973 15.68 7.68973 15.29 7.29973L8.71 0.699727C8.32 0.309727 7.69 0.309727 7.3 0.699727C6.91 1.08973 6.91 1.71973 7.3 2.10973L12.17 6.99973H1C0.45 6.99973 0 7.44973 0 7.99973C0 8.54973 0.45 8.99973 1 8.99973Z" fill="white"/>
                            </svg>
                          </div>
                        </div>
                    </div>


    );
};

export default connect()(Quote);
