// Import dependencies
import React from "react";
// Import components
import Layout from "../layouts/layout";
import SEO from "../components/seo/seo";
import HeaderSeparator from "../components/separators/headerSeparator";
import {NewBoxComponent, PageContainer} from "../styled-components/UILibrary";
import SectionSeparator from "../components/separators/sectionSeparator";
import RecycleNowForm from "../components/form/forms/re-recycleNowForm";
import SearchBar from "../components/search-bar/searchBar";
import CategoryDrawer from "../components/cateogry-drawer/categoty-drawer";
import Header from "../components/header/header";
// Import styles
import {Col, Row} from "antd";
import "./recycle.css";
import {Separator} from "../components/separators/separator"
import {navigate} from "gatsby";
import {connect} from "react-redux";


/* Recycle Now page */
const RecycleNow = ({location: {state}, store}) => {
    const [isFetchedData, setIsFetchedData] = React.useState(false);
    if (typeof window !== 'undefined' && !state) {
        navigate("/", {});
    }

    const {productId, productName, productInfo, key, fromLog, itemLog} = state ? state : {};

    let showDrawerState = false;
    const showDrawer = () => {
        if (showDrawerState) {
          showDrawerState = false;
        } else {
          showDrawerState = true;
        }
    };

    let productDetails = Object.values(store.allProductsById);
    let brandslist = productDetails
    .filter((product) => {
      return product.Product.toLowerCase().replace("other ", "").startsWith(productName.split(" ")[0].toLowerCase() + " ")
    })
    .map((product) => {
      if ((product.Department == "Books" || product.Department == "Cookware" || product.Department == "Clothes") && !(product.Product.startsWith("OTHER"))) {
        return product.Product.replace("OTHER ", "").replace(" " + product.SubCategory.toUpperCase(), "")
      } else if (product.Department == "Electronics" && product.Product.startsWith("OTHER") && product.Product !=  "OTHER " + product.SubCategory.toUpperCase()) {
        return product.Product.replace("OTHER ", "").replace(" " + product.SubCategory.toUpperCase(), "")
      } else {
        return false
      }
    })
    .filter((value, index, self) => {
      return self.indexOf(value) === index && value != "" ;
    })
    .slice(0, 4)
    .map((product, prodIndex) => {
      return product
    });

    const productSubCategory = productInfo && productInfo.SubCategory ? " " + productInfo.SubCategory.toUpperCase() : "";
    const productBulkName = "OTHER " + brandslist[0] + productSubCategory;
    const productBulkInfo =   Object.values(store.allProductsById)
      .find((product) => {
        return product.Product == productBulkName;
      });

    return (
      <RecycleNowForm
        addCartLogItem={fromLog}
        itemLog={itemLog}
        productId={productId}
        productName={productName}
        productInfo={productInfo}
        fetchedCallback={() => setIsFetchedData(true)}
        keyItem={key + "1"}
        selectClick={showDrawer}
        brand={brandslist[0]}
        productBulkId={productInfo && productInfo.ProductID ? productInfo.ProductID : null}
        productBulkName={productBulkName}
        productBulkInfo={productBulkInfo}
      />

    );

};

function readProductsList(storeState) {
    let store = {
        allProductsById: storeState.product.productsIndexedById
    };
    return {store};
}

export default connect(readProductsList)(RecycleNow);
