
// Import dependencies
import React from "react";

// Import components
import { Collapse } from "antd";

// Import styles
import "./accordion.css";
import { Constants } from "../../constants/Constants";

// Import icons
import Plus from "../../assets/icons/plus-green.svg";
import Minus from "../../assets/icons/minus-green.svg";

// Define custom accordion panel
const { Panel } = Collapse;

/*
    Accordion component
*/
const Accordion = ({ title, content, styles, defaultActiveKey }) => (
  <Collapse
    className="accordion"
    defaultActiveKey={defaultActiveKey}
    bordered={false}
    accordion
    expandIconPosition="right"
    expandIcon={({ isActive }) =>
      isActive ? (
        <Minus style={{ width: 26, marginRight: -5 }} alt="minus icon" />
      ) : (
        <Plus style={{ width: 19 }} alt="plus icon" />
      )
    }
    style={styles ? styles : { width: "100%", marginBottom: 25 }}
  >
    <Panel header={title} key="1">
      <p>{content}</p>
    </Panel>
  </Collapse>
);

export default Accordion;
