/*
    Contains data for input fields like dropdowns
*/
export const Member = 'Member';
export const NonMember = 'Non-Member';

export const Function =  {
  noProblems: "No problems",
  malfunctioning: "Malfunctioning",
  deadBattery: "Dead battery"
}

export const FunctionSelect = [
  {
    value: Function.noProblems
  },
  {
    value: Function.malfunctioning
  },
  {
    value: Function.deadBattery
  }
];

export const Condition =  {
  looksNew: "Looks new",
  clearlyUsed: "Clearly used",
  notGood: "Not good"
}

export const ConditionSelect = [
  {
    value: Condition.looksNew
  },
  {
    value: Condition.clearlyUsed
  },
  {
    value: Condition.notGood
  }
];

export const Title = [
  {
    value: "Mr."
  },
  {
    value: "Ms."
  },
  {
    value: "Mrs."
  }
];

export const CreditPreference = [
  {
    value: "Pay Now"
  },
  {
    value: "Bank transfer"
  },
  {
    value: "PayPal"
  }
];

export const CreditPreferenceInputs = {
  "Bank transfer": [
    {
      placeholder: "Beneficiary name",
      error: "Please enter valid beneficiary name.",
      type: "string"
    },
    {
      placeholder: "Beneficiary bank",
      error: "Please enter valid bank name.",
      type: "string",
      options: [
        { value: "DBS" },
        { value: "UOB" },
        { value: "OCBC" },
        { value: "MAYBANK" },
        { value: "STANDARD CHARTERED" },
        { value: "HSBC" },
        { value: "CITIBANK SINGAPORE" },
        { value: "ANZ BANKING GROUP" },
        { value: "BANK OF CHINA LIMITED" },
        { value: "BNP PARIBAS" },
        { value: "CIMB BANK BERHAD" },
        { value: "CITIBANK NA" },
        { value: "DEUTSCHE BANK AG" },
        { value: "HL BANK" },
        { value: "HSBC (CORPORATE)" },
        { value: "ICICI BANK LIMITED" },
        { value: "ICBC LIMITED" },
        { value: "MIZUHO BANK LIMITED" },
        { value: "MALAYAN BANKING BERHAD SINGAPORE " },
        { value: "RHB BANK BERHAD" },
        { value: "SUMITOMO MITSUI BANKING CORPORATION" },
        { value: "SING INVESTMENTS & FINANCE LIMITED" },
        { value: "THE BANK OF TOKYO-MITSUBISHI UFJ LTD" }
      ]
    },
    {
      placeholder: "Beneficiary account number",
      error: "Please enter valid account number.",
      type: "string"
    }
  ],
  PayPal: [
    {
      placeholder: "Email address",
      error: "Please enter valid email.",
      type: "email"
    }
  ],
  "Pay Now": [
    {
      placeholder: "Phone number",
      error: "Please enter valid phone number.",
      type: "string",
      phoneNumber: true
    }
  ]
};

export const CollectionMethod = [
  {
    value: "We pick up"
  },
  {
    value: "I drop-off at POP station"
  }
];

export const Address = 'Street name / Blk. / Unit No.';

export const CollectionMethodInputs = {
  "We pick up": [
    {
      isDate: true,
      id: "pick-up-date",
      error: "Please enter a valid date.",
      placeholder: "Collection Date",
      type: "object"
    },
    {
      isSelect: true,
      placeholder: "Collection Time",
      error: "Please select collection time",
      data: [
        {
          value: "09am - 10am"
        },
        {
          value: "10am - 11am"
        },
        {
          value: "11am - 12pm"
        },
        {
          value: "12pm - 01pm"
        },
        {
          value: "01pm - 02pm"
        },
        {
          value: "02pm - 03pm"
        },
        {
          value: "03pm - 04pm"
        },
        {
          value: "04pm - 05pm"
        },
        {
          value: "05pm - 06pm"
        },
        {
          value: "06pm - 07pm"
        },
        {
          value: "07pm - 08pm"
        },
        {
          value: "08pm - 09pm"
        },
        {
          value: "09pm - 10pm"
        },
        {
          value: "10pm - 11pm"
        },
        {
          value: "11pm - 12am"
        }
      ]
    },
    {
      placeholder: Address,
      error: "Please enter valid address.",
      type: "string"
    },
    {
      placeholder: "Postal code",
      error: "Please enter valid postal code.",
      type: "string",
      numeric: true,
    },
    {
      isSelect: true,
      placeholder: "Country",
      error: "Please select country.",
      disabled: true,
      data: [
        {
          value: "Singapore"
        }
      ]
    }
  ],
  "I drop-off at POP station": [
    {
      isDate: true,
      id: "drop-off-date",
      error: "Please enter a valid date.",
    type: "object"
    },
    {
      isSelect: true,
      placeholder: "Drop-off location",
      error: "Please select drop-off location"
    }
  ]
};

export const PackaginguUnit =  {
  small: "Small bags/boxes (<5kg)",
  medium: "Medium bags/boxes (5-10kg)",
  large: "Large bags/boxes (>20kg)"
}

export const PackaginguUnitSelect = [
  {
    value: PackaginguUnit.small
  },
  {
    value: PackaginguUnit.medium
  },
  {
    value: PackaginguUnit.large
  }
];

export const EstimatedPackagingUnits =  {
  estimation1: "1-5",
  estimation2: "6-10",
  estimation3: "11-20",
  estimation4: ">20"
}




export const EstimatedPackagingUnitsSelect = [
  {
    value: EstimatedPackagingUnits.estimation1
  },
  {
    value: EstimatedPackagingUnits.estimation2
  },
  {
    value: EstimatedPackagingUnits.estimation3
  },
  {
    value: EstimatedPackagingUnits.estimation4
  }
];